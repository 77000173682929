'use strict';

const platformUtils = require('santa-platform-utils');
const _ = require('lodash');
const scriptsValidator = require('../scriptsValidator');
const workerUtils = require('../workerUtils');
const constants = require('../../constants/constants');
const scriptsHandler = require('../scriptHandler');
const loggingUtils = require('../../utils/loggingUtils');
const {bi, fedops, ACTION_NAMES} = loggingUtils;
const platformServices = require('../../platformServices');
const experiments = require('../../stores/selectors/experiments');
const { OPENED_EXPERIMENTS, CSRF_TOKEN, WIX_CODE_VIEWER_APP} = require('../../constants/store');
const wixCodeUtils = require('../../utils/wixCodeUtils');
const {measurePerformanceEnd, measurePerformanceStart} = require('../workerUtils');

const getArtifactVersion = (wixCodeBase, isBolt) => {
  const viewerName = isBolt ? 'bolt' : 'santa';
  const viewerVersion = wixCodeBase && _.head(wixCodeBase.match(/\d[\d.]*\d/));
  return `${viewerName}-${viewerVersion}`;
};

function createBootstrapHandler({ store, messageService, pubSubService }) {
  function freezeGlobals() {
    Object.freeze(WeakMap.prototype);
  }

  function importScripts(url, scriptName, {onSuccess, onFailure, onFinally, verifyImport} = {}) {
    const params = {name: ACTION_NAMES.SCRIPT_LOADED, details: scriptName};
    const {reportAppLoadingPhaseStart, reportAppLoadingPhaseFinish} = fedops.getAppLoadingPhaseReportFunctions(params);
    reportAppLoadingPhaseStart();
    const beforeLoad = Date.now();
    try {
      scriptsHandler.importScripts(url, verifyImport);
      if (_.isFunction(onSuccess)) {
        onSuccess();
      }
      reportAppLoadingPhaseFinish({duration: _.now() - beforeLoad});
    } catch (err) {
      /*eslint-disable no-console*/
      console.error(`Failed to load script: ${scriptName}, url: ${url}`);
      bi.reportPlatformRenderError({
        duration: _.now() - beforeLoad,
        name: ACTION_NAMES.SCRIPT_LOAD_FAILED,
        details: JSON.stringify({
          scriptName,
          scriptUrl: url
        }),
        error: err.message
      });
      if (_.isFunction(onFailure)) {
        onFailure(err);
      }
    }
    if (_.isFunction(onFinally)) {
      onFinally();
    }
  }

  function loadSdk({parameters, namespacesSdkSource, externalComponentsSource, isDebug}) {
    self.openExperiments = store.getValue(OPENED_EXPERIMENTS);
    self.monitoringServices = platformServices.getApi().monitoring;
    self.wix = require('@wix/wixcode-sdk');
    self.wix.__INTERNAL__.initEnv(parameters);
    self.wix.__INTERNAL__.initUtilities({
      richTextUtils: platformUtils.richTextUtils,
      uriUtils: platformUtils.uriUtils,
      linkUtils: platformUtils.linkUtils,
      backgroundUtils: platformUtils.backgroundUtils,
      repeaterUtils: platformUtils.repeaterUtils,
      videoUrlsUtils: platformUtils.videoUrlsUtils,
      typeUtils: platformUtils.typeUtils,
      widgetUtils: platformUtils.widgetUtils,
      mediaSrcHandler: platformUtils.mediaSrcHandler,
      pubSubService,
      messageService
    });
    delete self.openExperiments;
    delete self.monitoringServices;
    loadNamespacesSdk(namespacesSdkSource);
    loadExternalComponents(externalComponentsSource);
    const sdk = self.wix;
    const openExperiments = store.getValue(OPENED_EXPERIMENTS);
    if (!isDebug && _.includes(openExperiments, 'sv_cleanWorkerGlobals')) {
      delete self.wix;
      delete self.postMessage;
    }
    return sdk;
  }

  function loadNamespacesSdk(sdkUrl) {
    importScripts(sdkUrl, 'wixcode-namespaces', {onFailure: workerUtils.throwError});
  }

  function loadwSpy(wSpyParam) {
    if (wSpyParam) {
      importScripts(constants.WSPY_LATEST_DSN, 'wspy.js', {
        onSuccess: () => {
          self.wSpy = self.initWorkerHost && self.initWorkerHost({ settings: constants.wSpySettings, wSpyParam });
        },
        onFailure: workerUtils.throwError
      });
    }
  }

  function loadExternalComponents(sdkExternalComponentsUrl) {
    importScripts(sdkExternalComponentsUrl, 'wixcode-components', {onFailure: workerUtils.throwError});
  }

  function shouldLoadWixCodeApp(applications, shouldUseWixCodeScripts) {
    const isDependsOnWixCodeScripts = app => app.id === constants.DATA_BINDING_APP_DEF_ID || app.shouldUseWixCodeScripts;
    return shouldUseWixCodeScripts || _.some(applications, isDependsOnWixCodeScripts);
  }

  function loadWixCodeViewerApp(wixCodeViewerAppSource, {viewMode}) {
    const wixCodeViewerApp = workerUtils.importScriptsAsNpmModule(self, wixCodeViewerAppSource, 'wixCode', 'wixCodeViewerApp', scriptsHandler);

    store.setValue(WIX_CODE_VIEWER_APP, wixCodeViewerApp);

    wixCodeViewerApp.wixCodeBootstrap(self.console, wixCodeUtils.sendLogMessageToViewer.bind(self, self.postMessage), {viewMode});
  }

  function loadFedOpsAndBi({biSessionData, sdkParameters, wixCodeBase, isBolt, openExperiments, isDebug}) {
    const {renderingEnv, viewMode} = sdkParameters;
    const artifactVersion = getArtifactVersion(wixCodeBase, isBolt);
    const biSession = _.assign(
      biSessionData,
      {
        isServerSide: renderingEnv === 'backend',
        artifactVersion
      }
    );

    const {getFedOpsLoggers, getBiLoggers, getBiLoggerFactory} = platformServices;
    const biStoreData = {
      biSessionData: biSession,
      isDebug,
      biSampleByRequestId: _.includes(openExperiments, 'biSampleByRequestId'),
      reportTrace: _.includes(openExperiments, 'sv_reportTrace'),
      fedopsNoSampling: _.includes(openExperiments, 'fedopsNoSampling'),
      isPreview: viewMode !== 'site'
    };

    loggingUtils.init(biStoreData, messageService, {getFedOpsLoggers, getBiLoggers, getBiLoggerFactory});
    fedops.reportPlatformLoadStarted();
  }

  return function handleBootstrap(messageData, appsStore) {
    measurePerformanceStart('bootstrap');
    const bootstrapStartTime = Date.now();
    const bootstrapArgs = messageData.bootstrapArguments;
	  store.setValues(messageData.bootstrapArguments);
    const {
      namespacesSdkSource,
      externalComponentsSource,
      wixCodeNamespacesAndElementorySupportSource,
      wixCodeViewerAppSource,
      sdkParameters,
      openExperiments,
      csrfToken,
      isDebug,
      shouldUseWixCodeScripts,
      biSessionData,
      wSpyParam,
      wixCodeBase
    } = bootstrapArgs;

    store.setValue(OPENED_EXPERIMENTS, openExperiments);
    freezeGlobals();
    const applications = JSON.parse(bootstrapArgs.applications);
    const wixCodeMovedToViewerApp = experiments.isExperimentOpen(store, 'sv_moveWixCodeToViewerApp');

    if (!wixCodeMovedToViewerApp) {
      const loadWixCodeApp = shouldLoadWixCodeApp(applications, shouldUseWixCodeScripts);

      if (loadWixCodeApp) {
        const viewMode = _.get(sdkParameters, 'viewMode');
        loadWixCodeViewerApp(wixCodeViewerAppSource, {viewMode});
      }
    } else {
      importScripts(wixCodeNamespacesAndElementorySupportSource, 'wixCodeNamespacesAndElementorySupport');
    }
    if (!sdkParameters) {
      throw new Error(`Could not load user code: \`sdkParameters\` has an invalid value: ${sdkParameters}`);
    }

    const isBolt = Boolean(messageData.isBolt);
    loadFedOpsAndBi({biSessionData, sdkParameters, wixCodeBase, isBolt, openExperiments, isDebug});
    store.setValue(CSRF_TOKEN, csrfToken);
    scriptsValidator.validate(applications, ['id', 'url']);

    loadwSpy(wSpyParam);
    const sdk = loadSdk({parameters: sdkParameters, namespacesSdkSource, externalComponentsSource, isDebug});
    workerUtils.importModules(applications, appsStore, scriptsHandler);
    messageService.sendBootstrapMessage();
    measurePerformanceEnd('bootstrap');
    loggingUtils.bi.trace({duration: _.now() - bootstrapStartTime, name: ACTION_NAMES.BOOTSTRAP_DONE});
    return sdk;
  };
}

module.exports = createBootstrapHandler;
